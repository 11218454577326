<template>
  <div>
    <loading :active.sync="isLoading" :full-poge="false"></loading>
    <b-card>
      <b-card-title class="card-title mx-2 mt-2">
        <b-row class="row">
          <b-col cols="6">
            <button class="btn btn-sm btn-primary mx-1" @click="onXml()">
              <feather-icon size="15" icon="DownloadIcon" class="mr-50" />
              <span style="font-size: 15px">Descargar XLS</span>
            </button>
            <button class="btn btn-sm button-primary" @click="onAdd" v-if="isAdmin">
              <feather-icon size="15" icon="PlusSquareIcon" class="mr-50" />
              <span style="font-size: 15px">Agregar Tienda</span>
            </button>
          </b-col>
          <b-col cols="6">
            <filter-bar-component
              ref="search"
              v-on:search="onSearch"
            ></filter-bar-component>
          </b-col>
        </b-row>
      </b-card-title>
      <b-card-body class="py-2 px-0">
        <full-table-component
          ref="fulltable"
          :fields="fields"
          :perPage="100"
          url="/customers"
        >
          <template slot="percentage-slot" slot-scope="props">
            <div v-if="props.rowData.percentage > 0" v-b-tooltip.hover title="INCREMENTO">
              <span style="color: green">
                <feather-icon size="20" icon="ArrowUpIcon" />
              </span>
              {{ props.rowData.percentage }} %
            </div>

            <div v-if="props.rowData.percentage < 0" v-b-tooltip.hover title="DESCUENTO">
              <span style="color: red">
                <feather-icon size="20" icon="ArrowDownIcon" />
              </span>
              {{ props.rowData.percentage * -1 }} %
            </div>

            <div v-if="props.rowData.percentage == 0">
              {{ props.rowData.percentage }} %
            </div>
          </template>

          <template slot="status-slot" slot-scope="props">
            <toggle-button
              v-model="props.rowData.status"
              @click.native="changeStatus(props.rowData)"
              color="#f68d1e"
              :sync="true"
              :labels="true"
              v-if="isAdmin"
            />
            <div v-else style="padding: 5px">
              <span
                style="color: white; font-weight: bold; border-radius: 6px; padding: 5px"
                v-bind:style="{
                  background: props.rowData.status ? '#f68d1e' : '#7a848a',
                }"
                >{{ props.rowData.status ? "ON" : "OFF" }}</span
              >
            </div>
          </template>
          <template slot="buttons-slot" slot-scope="props">
            <b-dropdown
              class="p-0"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon size="20" icon="ListIcon" />
              </template>
              <b-dropdown-item @click="onView(props.rowData)">
                <feather-icon size="20" icon="SearchIcon" />
                Ver detalles
              </b-dropdown-item>
              <b-dropdown-item @click="onEdit(props.rowData)" v-if="isAdmin">
                <feather-icon size="20" icon="Edit3Icon" />
                Editar
              </b-dropdown-item>
              <b-dropdown-item @click="onDelete(props.rowData)" v-if="isAdmin">
                <feather-icon size="20" icon="TrashIcon" />
                Eliminar
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </full-table-component>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FullTableComponent from "@/layouts/components/FullTableComponent";
import FilterBarComponent from "@/layouts/components/FilterBarComponent";

import CustomerService from "@/services/CustomerService";
import AlertService from "@/services/AlertService";
import UtilService from "@/services/UtilService";
import UserService from "@/services/UserService";

import CustomerEdit from "./CustomerEdit";
import CustomerView from "./CustomerView";

export default {
  name: "CustomerAll",
  components: {
    FilterBarComponent,
    FullTableComponent,
  },
  data() {
    return {
      isLoading: false,
      isAdmin: UserService.isAdminOrStaff(),
      isReseller: UserService.isReseller(),
      fields: [
        {
          name: "id",
          title: "Id",
          sortField: "id",
        },
        {
          name: "created_at",
          title: "Creado",
          sortField: "created_at",
          formatter: (value) => {
            return UtilService.date(value);
          },
        },
        {
          name: "percentage-slot",
          title: "Desc/Inc",
          sortField: "stapercentagetus",
        },
        {
          name: "type",
          title: "Tipo Tienda",
          sortField: "type",
        },
        {
          name: "brand",
          title: "Empresa",
          sortField: "brand",
        },
        {
          name: "full_name",
          title: "Nombres",
          sortField: "full_name",
        },
        {
          name: "collection",
          title: "Servicio de Colecta",
          sortField: "collection",
          formatter: (value) => {
            return UtilService.serviceCollection(value);
          },
        },
        {
          name: "retirement",
          title: "Servicio de Entrega",
          sortField: "retirement",
          formatter: (value) => {
            return UtilService.serviceRetirement(value);
          },
        },
        {
          name: "status-slot",
          title: "Estado",
          sortField: "status",
        },
        {
          name: "debt",
          title: "Deuda",
          formatter: (value) => {
            return this.$options.filters.currency(value);
          },
        },
        {
          name: "location",
          title: "Localidad",
          sortField: "location",
        },
        {
          name: "province",
          title: "Provincia",
          sortField: "province",
        },
        {
          name: "buttons-slot",
          title: "Acciones",
          titleClass: "text-right",
          dataClass: "text-right align-middle",
        },
      ],
    };
  },
  methods: {
    /**
     *
     */
    async changeStatus(item) {
      if (item.status) {
        // Desjabilitar usuario
        const result = await AlertService.questionText(
          "¿Estás seguro?",
          "Estas seguro de querer Deshabilitar el cliente " + item.full_name,
          { deshabilitarCustomer: "Deshabilitar" }
        );
        if (result.value !== undefined) {
          this.isLoading = true;
          CustomerService.disabled(item, {
            reason: result.value,
          })
            .then(() => {
              this.isLoading = false;
              this.$refs.fulltable.refresh();
            })
            .catch((error) => {
              this.isLoading = false;
              console.error(error);
              if (error.response.status == 422) {
                item.status = !item.status;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Error`,
                    text: `${error.response.data.errors.reason[0]}`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
                return;
              }
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  text: `Error al tratar de habilitar el cliente`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            });
        } else {
          item.status = !item.status;
        }
      } else {
        // habilidaar usuario
        const result = await AlertService.question(
          "¿Estás seguro?",
          "Estas seguro de querer habilitar el cliente " + item.full_name,
          { habilitarCustomer: "Habilitar" }
        );
        if (result.value) {
          this.isLoading = true;
          CustomerService.enabled(item, {
            reason: result.value,
          })
            .then(() => {
              this.isLoading = false;
              this.$refs.fulltable.refresh();
            })
            .catch((error) => {
              this.isLoading = false;
              console.error(error);
              if (error.response.status == 422) {
                item.status = !item.status;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Error`,
                    text: `${error.response.data.errors.reason[0]}`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
                return;
              }
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  text: `Error al tratar de habilitar el cliente`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            });
        } else {
          item.status = !item.status;
        }
      }
    },

    /**
     *
     */
    onSearch(event) {
      this.$refs.fulltable.search(event, {}, true);
    },

    /**
     *
     */
    add() {
      this.$refs["edit-Customer"].create();
    },

    /**
     *
     */
    async onDelete(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de eliminar el cliente con el id " + item.id
      );
      if (result.value) {
        this.isLoading = true;
        CustomerService.destroy(item)
          .then(() => {
            this.isLoading = false;
            AlertService.info();
            this.$refs.fulltable.refresh();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de eliminar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    /**
     *
     */
    async onAdd(data) {
      this.$modal.show(
        CustomerEdit,
        {
          edit: false,
          customer: data,
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onView(item) {
      this.$modal.show(
        CustomerView,
        {
          item,
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onEdit(item) {
      this.$modal.show(
        CustomerEdit,
        {
          edit: true,
          customer_id: item.id,
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onXml() {
      this.isLoading = true;
      const search = this.$refs.search ? this.$refs.search.filterText : "";
      CustomerService.export({ search })
        .then((response) => {
          window.open(process.env.VUE_APP_API + '/operation_export/' + response.data.id, "_blank");
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (this.$route.query.open) {
      this.onView({
        id: this.$route.query.open,
      });
    }
    if (!UserService.isAdminOrStaffOrReseller()) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0 !important;
}

.table.table-sm td {
  padding: 10px 5px !important;
}
</style>
